<template>
  <div>
    <div v-if="!loaded" class="placeholder"></div>
    <div v-show="loaded">
      <header class="masthead" id="headerId">
        <div class="overlay"></div>
        <div class="mx-auto">
          <h1>
            <img
              class="img-fluid"
              src="https://cdn.monstersofthenorth.com/content/MonstersOfTheNorthLogo_large_dropshadow.png"
              alt="Monsters of the North Logo. A blind accessible game for PC"
            />
          </h1>
          <a
            href="https://store.steampowered.com/app/1685930/Monsters_of_the_North/"
            target="_blank"
          >
            <img
              class="steam-btn"
              src="../assets/img/steam_wishlist_big.png"
              alt="Wishlist on Steam button"
            />
          </a>
        </div>
      </header>
      <div class="container">
        <div class=""></div>
        <div class="row">
          <div class="col-sm text-left">
            <p>
              <b>Monsters of the North</b> is a Monster Taming Turnbased RPG
              with Roguelite elements. It's inspired by games like Fire Emblem,
              Pokemon, Pyre and Darkest Dungeon. It's still in early
              development.
            </p>
            <h2>Story</h2>
            <p>
              In the ruins of humanity, inteligent and speaking monsters roam
              the nordic landscape. But something is rumbling in the deep: an
              ancient being has awoken and is preparing to extinguish life in
              the North. One human and a party of monsters are the only things
              standing in it's way.
            </p>
          </div>
          <div class="col-lg">
            <figure>
              <img
                class="img-fluid-extra rounded border"
                src="https://cdn.monstersofthenorth.com/content/Game Screen Sketch_v6_green_Idle_v3.gif"
                alt="Animated battle: 2 Monsters face eachother, and text spelling out the words 'quote' Someone Is Going to Die 'End Quote' shows up on screen. The monster further back on the right side is a snakelike creature emerging from a human skull. It has 16 Hit points. The monster on the left is a hand with an eyeball in the center. It has 15 hit points. The scene takes place on grass in the middle of a fjord between two mountains. A city can be seen in the distance."
              />
              <figcaption>Early Battle Concept</figcaption>
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="col-md text-left">
            <h2>Features</h2>
            <ul>
              <li>
                Befriend monsters and get to know them
              </li>
              <li>
                Battle enemies in turn based elemental combat
              </li>
              <li>
                Build monsters relationships with eachother and follow their
                questlines
              </li>
              <li>
                Permanent death and high stakes, monsters react and grieve for
                fallen allies
              </li>
              <li>
                Replayable roguelite runs with a continuous story
              </li>
              <li>
                Online Multiplayer Battling vs other players
              </li>
            </ul>
          </div>
          <div class="col-lg">
            <figure>
              <img
                class="img-fluid-extra rounded border"
                src="https://monstermakerstorage.blob.core.windows.net/content/Overworld.gif"
                alt="Animated Overhead view, a young adult character is walking in green fields and next to mountains and rocks. They are being followed by a small hedgehog monster with icicles on it's back. There are a bunch of birch trees sprouting randomly from ground. Other monsters can be seen roaming around"
              />
              <figcaption>Early Overworld concept</figcaption>
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="text-left">
            <h2>Monsters</h2>
            <GetSprites></GetSprites>
            <p style="text-align:center">And many more!</p>
            <Accessibility></Accessibility>
          </div>
          <div class="row text-left">
            <h2>Mod Support</h2>
            <p>
              The asset pipeline is structured to make it easy for modders and
              non-game developers to add their own monsters to the game.
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import GetSprites from "./GetSprites.vue";
import Accessibility from "./Accessibility.vue";
export default {
  name: "GameDescription",
  props: {
    msg: String
  },
  components: {
    GetSprites,
    Accessibility
  },
  setup() {
    const loaded = ref(false);
    const imageUrl = "https://cdn.monstersofthenorth.com/content/Landscape.jpg";
    onMounted(() => {
      let bgElement = document.getElementById("headerId");
      console.log(bgElement);
      let preloaderImg = document.createElement("img");
      preloaderImg.src = imageUrl;
      preloaderImg.addEventListener("load", event => {
        bgElement.style.backgroundImage = `url(${imageUrl})`;
        loaded.value = true;
        preloaderImg = null;
      });
    });
    return {
      loaded
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0;
}
li {
  font-size: 1.31rem;
  // display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
header.masthead {
  position: relative;
  background: center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin-bottom: 6rem;
}
p {
  font-size: 1.31rem;
}
.placeholder {
  padding-top: 30rem;
  padding-bottom: 30rem;
}
</style>
