<template>
  <Signup />
</template>

<script>
// @ is an alias to /src
import Signup from "@/components/SignUp.vue";

export default {
  name: "SignUp",
  components: {
    Signup
  }
};
</script>
