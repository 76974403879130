<template>
  <div class="min-vh-100">
    <BlogPost :post="recentPost" :loaded="loaded"></BlogPost>
    <BlogPostsList :currentPost="recentPost.id"></BlogPostsList>
  </div>
</template>
<script>
import BlogPost from "@/components/BlogPost.vue";
import BlogPostsList from "@/components/BlogPostsList.vue";
import { ref, onMounted } from "vue";
import marked from "marked";
import DOMPurify from "dompurify";
export default {
  name: "News",
  components: {
    BlogPost,
    BlogPostsList
  },
  setup() {
    const recentPost = ref({
      title: "Loading Latest Blogpost",
      id: 0,
      created: "",
      author: "",
      score: 0,
      text: "",
      postType: ""
    });
    const loaded = ref(false);
    onMounted(() => {
      GetBlogPost();
    });

    function GetBlogPost() {
      const apiUrl = process.env.VUE_APP_API_DEST;
      fetch(apiUrl + "api/v1/blog/latest")
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            Promise.reject();
          }
        })
        .then(res => {
          recentPost.value = res;
          let text = marked(recentPost.value.text);
          recentPost.value.text = DOMPurify.sanitize(text);
          loaded.value = true;
        })
        .catch(error => {
          console.log(error);
        });
    }

    return {
      recentPost,
      loaded
    };
  }
};
</script>
