<template>
  <div class="col">
    <BlogPost :post="post" :loaded="loaded"></BlogPost>
    <button v-if="isAdmin" v-on:click.prevent="DeleteBlogPost(postId)">
      Delete
    </button>
  </div>
  <BlogPostsList :currentPost="postId"></BlogPostsList>
</template>
<script>
import { onBeforeRouteUpdate } from "vue-router";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import BlogPost from "@/components/BlogPost.vue";
import BlogPostsList from "@/components/BlogPostsList.vue";
import marked from "marked";
import DOMPurify from "dompurify";
export default {
  name: "BlogPostView",
  components: {
    BlogPost,
    BlogPostsList
  },
  props: {
    postId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const user = store.getters.getUser;
    console.log(user.roles);
    const isAdmin = computed(() => user.roles.includes("Admin"));
    const apiUrl = process.env.VUE_APP_API_DEST;
    const post = ref({
      title: "hello",
      id: 0,
      created: "",
      author: "",
      score: 0,
      text: "",
      postType: ""
    });
    const loaded = ref(false);

    onBeforeRouteUpdate(async (to, from) => {
      console.log("Triggered");
      GetBlogPost(to.params.postId);
    });

    onMounted(() => {
      GetBlogPost(props.postId);
    });

    function GetBlogPost(blogId) {
      fetch(apiUrl + "api/v1/blog/" + blogId)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(res => {
          post.value = res;
          let text = marked(post.value.text);
          post.value.text = DOMPurify.sanitize(text);
          loaded.value = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
    function DeleteBlogPost(blogId) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      console.log(blogId);
      fetch(apiUrl + "api/v1/blog/" + blogId, {
        headers: headers,
        method: "DELETE"
      })
        .then(response => {
          if (response.ok) {
            return true;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    return {
      post,
      isAdmin,
      apiUrl,
      onBeforeRouteUpdate,
      loaded,
      DeleteBlogPost
    };
  }
};
//Start Chrome in app mode
//"path\to\chrome\chrome.exe" --app=http://facebook.com
</script>
