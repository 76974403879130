<template>
  <div class="mechs-login" style="margin:10rem;">
    <h1>Thanks for logging in!</h1>
    <p>You can close this window and return to the game now</p>
  </div>
</template>

<script>
export default {
  name: "MechsLogin",
  setup() {}
};
</script>
