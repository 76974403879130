<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link class="navbar-brand" to="/">
      <img
        class="title-btn"
        alt="Monsters of the North Logo"
        src="@/assets/img/MOTN_Logo_small.png"
      />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto">
        <li>
          <router-link class="nav-item nav-link" to="/news">News</router-link>
        </li>
        <li>
          <router-link class="nav-item nav-link" to="/about">About</router-link>
        </li>
        <li>
          <router-link class="nav-item nav-link" to="/signup"
            >Signup</router-link
          >
        </li>
        <li>
          <router-link class="nav-item nav-link" to="/faq">FAQ</router-link>
        </li>
        <li>
          <router-link class="nav-item nav-link" to="/accessibility"
            >Accessibility</router-link
          >
        </li>
        <li v-if="loggedIn == 2">
          <router-link class="nav-item nav-link" to="/create"
            >Create</router-link
          >
        </li>
        <li v-if="loggedIn == 2">
          <router-link class="nav-item nav-link" to="/editor"
            >Editor</router-link
          >
        </li>
      </ul>
      <div>
        <a
          v-if="loggedIn == 0"
          class="nav-item nav-link"
          :href="hrefCallbackLink"
          ><img
            style="max-width:30%"
            src="https://cdn.monstersofthenorth.com/content/Discord-Logo_small.svg"
            alt="Discord Login"
          />
          Login</a
        >
        <div v-if="loggedIn == 1" class="spinner-border">
          <span class="progress-left">
            <span class="progress-bar"></span>
          </span>
        </div>
        <div v-if="loggedIn == 2">
          <img class="avatar" :src="user.avatarUrl" :alt="user.userName" />
          <div>{{ user.userName }}</div>
        </div>
      </div>
    </div>
  </nav>
  <div></div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "Header",
  setup() {
    const hrefCallbackLink =
      process.env.NODE_ENV === "development"
        ? "https://discord.com/api/oauth2/authorize?client_id=819895297069154336&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fauth%2Foauth2%2Fcallback%2F&response_type=code&scope=identify%20email%20guilds&prompt=none"
        : "https://discord.com/api/oauth2/authorize?client_id=819895297069154336&redirect_uri=https%3A%2F%2Fmonstersofthenorth.com%2Fauth%2Foauth2%2Fcallback&response_type=code&scope=identify%20email%20guilds&prompt=none";
    const store = useStore();
    const loggingIn = computed(() => store.getters.getLoggingInStatus);
    const user = computed(() => store.getters.getUser);
    const loggedIn = computed(() => store.getters.getLoginStatus);
    return {
      user,
      store,
      hrefCallbackLink,
      loggingIn,
      loggedIn
    };
  }
};
</script>
<style>
.avatar {
  border-radius: 50%;
  width: 64px;
  height: 64px;
}
</style>
