<template>
  <div class="container">
    <img src="https://i.imgur.com/oiEj3eR.gif" alt="" />
    <BlogPostCreate></BlogPostCreate>

    <BlogPostsList></BlogPostsList>
    <PostSprite></PostSprite>
    <!-- This Prevents the default action of loading the page when we submit-->
  </div>
</template>

<script>
// import { computed, defineComponent, reactive, ref } from "vue";
import BlogPostCreate from "../components/BlogPostCreate.vue";
import BlogPostsList from "../components/BlogPostsList.vue";
import PostSprite from "../components/PostSprite.vue";
export default {
  name: "Editor",
  components: {
    BlogPostCreate,
    BlogPostsList,
    PostSprite
  }
  // function loadJson() {
  //     console.log(json.value)
  //     for(thing in json.fields)
  //     {
  //         console.log(thing)
  //     }
  // }

  // function download(content, fileName, contentType) {
  //     var a = document.createElement("a")
  //     var file = new Blob([JSON.stringify(content)], {type: contentType})
  //     a.href = URL.createObjectURL(file)
  //     a.download = fileName
  //     console.log("Clicked")
  //     a.click()
  // }
  // function downloadItems() {
  //     download(items.value, "items", "application/json")
  // }
};
//Start Chrome in app mode
//"path\to\chrome\chrome.exe" --app=http://facebook.com
</script>
