<template>
  <div class="home">
    <GameDescription msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import GameDescription from "@/components/GameDescription.vue";
// import { useRoute } from "vue-router";

@Options({
  name: "Home",
  components: {
    GameDescription
  },
  setup() {
    // const route = useRoute();
  }
})
export default class Home extends Vue {}
</script>
