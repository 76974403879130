<template>
  <div>
    <form v-on:submit.prevent="submit" class="validate" target="_blank">
      <div class="form container">
        <div class="row">
          <label class="form-label" for="name">Name</label>
          <input
            type="text"
            v-model="name"
            name="name"
            class="form-control-lg"
            placeholder="What's my name?"
            id="name"
          />
        </div>
        <div class="row">
          <label style="text-align-left" for="description">Description</label>
          <textarea
            v-model="description"
            name="description"
            class="form-control-lg"
            placeholder="Describe me"
            id="description"
          ></textarea>
        </div>
        <div class="row">
          <label class="form-label" for="name">Sprite Url</label>
          <input
            type="text"
            v-model="spriteUrl"
            name="spriteUrl"
            class="form-control-lg"
            placeholder="https://imgur.com/*"
            id="spriteUrl"
          />
        </div>
      </div>
    </form>
    <!-- <button  class="front btn btn-block btn-lg btn-primary" @click="showPreview">Preview </button> -->
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "CreateMonster",
  setup() {
    const store = useStore();
    const name = ref("");
    const description = ref("");
    const spriteUrl = ref("");
    const defense = ref(5);
    const power = ref(5);
    const fortitude = ref(5);
    const apiUrl = process.env.VUE_APP_API_DEST;
    onMounted(() => getStats());
    function submit() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        name: name.value,
        description: description.value,
        spriteUrl: spriteUrl.value,
        defense: defense.value,
        power: power.value,
        fortitude: fortitude.value
      });
      fetch(apiUrl + "api/v1/monsters/stats", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(function(response) {
          // handle success
          console.log(response)
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function getStats() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json"
      };
      fetch(apiUrl + "api/v1/monsters/stats", {
        method: "GET",
        headers: headers
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    return {
      submit,
      name,
      description,
      spriteUrl,
      defense,
      power,
      fortitude,
      getStats
    };
  }
};
</script>
<style></style>
