<template>
  <div class="container">
    <div v-if="!loaded">
      <h1>Loading blog post</h1>
      <p>Please wait while I fetch the latest news!</p>
      <div class="spinner-border">
        <span class="progress-left">
          <span class="progress-bar"></span>
        </span>
      </div>
    </div>
    <div v-if="loaded" class="col">
      <h1>{{ post.title }}</h1>
      <article class="blog">
        <div v-html="post.text"></div>
      </article>
      <!-- <ul class="comments">
        <li class="comment">Comment</li>
    </ul> -->
      <!-- <textarea name="AddComment" id="" cols="30" rows="10"></textarea>
    <button>Post</button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "BlogPost",
  props: {
    post: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      required: false
    }
  }
};
//Start Chrome in app mode
//"path\to\chrome\chrome.exe" --app=http://facebook.com
</script>
