<template>
  <div class="blogpost-list container">
    <div v-if="loaded" class="row">
      <div v-for="post in posts" v-bind:key="post.id">
        <div v-if="currentPost != post.id" class="blogpost-card">
          <!-- <img class="card-img-top" src="" alt="Card image cap"> -->
          <router-link class="card-body" :to="'/blog/post/' + post.id">
            <div class="blogpost-card-body">
              <h3 class="blogpost-card-title">{{ post.title }}</h3>
              <p class="card-text">{{ post.text }}</p>
              <div class="btn btn-primary">Read more</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from "vue";
export default {
  name: "BlogPostsList",
  props: {
    currentPost: {
      type: String,
      requred: false
    }
  },
  setup() {
    const posts = ref([
      {
        index: 0,
        title: "",
        id: "",
        created: "",
        author: "",
        text: "",
        score: 0,
        content: "",
        postType: ""
      }
    ]);
    const loaded = ref(false);
    onMounted(() => {
      getPosts();
    });
    function getPosts() {
      const apiUrl = process.env.VUE_APP_API_DEST;
      // Make a request for a user with a given ID
      const url = apiUrl + "api/v1/blog";
      //console.log(url);
      axios;
      fetch(url)
        .then(response => {
          //console.log(response);
          if (response.ok) {
            return response.json();
          }
        })
        .then(res => {
          posts.value = res;
          loaded.value = true;
          //console.log(posts.value)
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // posts.value.forEach(e =>
          // {
          //   let tex = marked(e.text.value)
          //   e.text.value = sanitizeHtml(tex)
          // })
        });
    }
    return {
      getPosts,
      posts,
      loaded
    };
  }
};
</script>
