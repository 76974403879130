<template>
  <div>
    <FrequentlyAskedQuestions></FrequentlyAskedQuestions>
  </div>
</template>
<script>
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.vue";
export default {
  name: "FAQ",
  components: { FrequentlyAskedQuestions }
};
</script>

<style scoped>
.row {
  padding-bottom: 2rem;
}
</style>
