
import { ref, defineComponent, computed } from "vue";
import {
  // Sprite,
  SpriteFrameDataDto,
  FrameTagDto,
  // ImageType,
  // AnimationDirection
} from "@/types/API/sprites/Spritetypes";
import { useStore } from "vuex";

export default defineComponent({
  name: "CreateSprite",
  props: {
    imageType: {
      required: true
    }
  },
  setup() {
    const store = useStore();
    const name = ref("");
    const description = ref("");
    const previewImage = ref<ArrayBuffer | string | null>("");
    const imageJson = ref<SpriteFrameDataDto>({
      frames: [],
      meta: {
        monsterMakerFormat: true,
        image: "none",
        size: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        frameTags: []
      }
    });

    const frameSize = ref(64);
    const imageWidth = ref(64);
    const imageHeight = ref(64);
    const frameRows = ref(5);
    const frameCols = ref(6);
    const previewGifs = ref();
    const useJson = ref(true);
    const imageMetaData = ref<SpriteFrameDataDto>({
      frames: [],
      meta: {
        monsterMakerFormat: true,
        image: "none",
        size: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        frameTags: []
      }
    });
    const apiUrl = process.env.VUE_APP_API_DEST;
    const funcUrl = process.env.VUE_APP_FUNC_DEST;
    const numberOfFrames = computed({
      get: () => frameRows.value * frameCols.value,
      set: value => {
        frameRows.value * frameCols.value;
      }
    });
    const computedRowsandCols = computed({
      get: () =>
        `grid-container g-row-${frameRows.value} g-col-${frameCols.value}`,
      set: value => {
        `grid-container g-row-${frameRows.value} g-col-${frameCols.value}`;
      }
    });
    function addAnimation(
      from: number,
      to: number,
      name: string,
      direction: string
    ) {
      imageMetaData.value.meta.frameTags.push({
        from: from,
        to: to,
        name: name,
        direction: direction
      } as FrameTagDto);
    }
    function addFrames(
      imageWidth: number,
      imageHeight: number,
      columns: number,
      rows: number
    ) {
      const frameX = imageWidth / columns;
      const frameY = imageHeight / rows;
      // const totalFrames = columns * rows
      for (let xCord = 0; xCord < rows; xCord++) {
        for (let yCord = 0; yCord < columns; yCord++) {
          imageMetaData.value.frames.push({
            filename: "filename",
            frame: {
              x: frameX * xCord,
              y: frameY * yCord,
              w: frameX,
              h: frameY
            }
          });
        }
      }
    }
    function submitFuncApp() {
      const headers = {
        "Content-Type": "application/json"
      };
      const body = JSON.stringify({
        image: previewImage.value,
        data: imageJson.value
      });
      console.log(body);
      fetch(funcUrl + "api/gif", {
        method: "POST",
        headers: headers,
        body: body
      })
        .then(response => {
          return response.json();
          // handle success
        })
        .then(response => {
          previewGifs.value = response;
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function submit() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        name: name.value,
        description: description.value,
        image_content: previewImage.value,
        image_type: 1,
        image_data: imageJson.value
      });
      fetch(apiUrl + "api/v1/monsters/sprites?language=english", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(function(response) {
          console.log(response);
          // handle success
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function uploadImage(e: any) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        if (e != null && e.target != null) {
          previewImage.value = e.target?.result;
          console.log(previewImage.value);
        }
      };
    }
    function uploadFile(e: any) {
      const jsonFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(jsonFile);
      reader.onload = e => {
        if (e != null && e.target != null && e.target.result != null) {
          imageJson.value = JSON.parse(
            e.target.result as string
          ) as SpriteFrameDataDto;
          //TODO: CONVERT FROM ASEPRITE JSON TO MOTN JSON?
          if (imageJson.value?.frames !== undefined) {
            imageJson.value.frames = Object.entries(imageJson.value.frames).map(
              ([k, v]) => v
            );
            console.log("It has not been defined");
          }
        }
        console.log(imageJson.value);
      };
    }
    return {
      submit,
      name,
      description,
      previewImage,
      imageJson,
      frameRows,
      frameCols,
      uploadImage,
      uploadFile,
      submitFuncApp,
      useJson,
      previewGifs,
      computedRowsandCols,
      numberOfFrames
    };
  }
});
