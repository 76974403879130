import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap";
import DOMPurify from "dompurify";
import marked from "marked";

// declare module '@vue/runtime-core' {
//   // declare your own store states
//   interface State {
//     count: number
//   }

//   // provide typings for `this.$store`
//   interface ComponentCustomProperties {
//     $store: Store<State>
//   }
const markedMixin = {
  methods: {
    marked: function(input: string) {
      return marked(input);
    }
  }
};
const sanitizeHtml = {
  methods: {
    purified: function(input: string) {
      return DOMPurify.sanitize(input);
    }
  }
};

createApp(App)
  .use(router)
  .use(store)
  .mixin(markedMixin)
  .mixin(sanitizeHtml)
  .mount("#app");
