<template>
  <div class="container">
    <div class="col-md text-left">
      <Accessibility></Accessibility>
    </div>
  </div>
</template>
<script>
import Accessibility from "@/components/Accessibility.vue";
export default {
  name: "AccessibilityView",
  components: {
    Accessibility
  }
};
</script>
