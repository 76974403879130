
import { onMounted } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ActionTypes } from "./store";
export default {
  components: {
    Header,
    Footer
  },
  props: {
    code: {
      type: String,
      required: false
    }
  },
  setup() {
    // const marked = require("marked")
    // const html = marked('# Marked in NODE JS \n\nRendered by **marked**.')
    const store = useStore();
    // const route = useRoute();
    onMounted(() => {
      store.dispatch(ActionTypes.RefreshToken);
    });
    return {};
  }
};
