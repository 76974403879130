<template>
  <div class="card create-sprite">
    <img
      :src="previewImage"
      class="create-sprite card-img-top uploading-image"
    />
    <div v-bind:class="computedRowsandCols">
      <div v-for="i in frameRows * frameCols" :key="i">
        <button class="grid-item">
          {{ i }}
          <!-- <button v-for="k in frameCols" :key="k" class="grid-item">col</button> -->
        </button>
      </div>
    </div>
    <form class="validate" target="_blank">
      <div class="card-body">
        <div class="row">
          <label class="form-label" for="name">Name</label>
          <input
            type="text"
            v-model="name"
            name="name"
            class="form-control-lg"
            placeholder="What's my name?"
            id="name"
          />
        </div>
        <div class="row">
          <label class="form-label" for="description">Description</label>
          <textarea
            v-model="description"
            name="description"
            class="form-control-lg"
            placeholder="If you were to describe me to someone, what would you say?"
            id="description"
          ></textarea>
        </div>
        <div class="row">
          <label class="form-label" for="name">Upload Spritesheet</label>
          <input
            type="file"
            accept="image/png"
            @change="uploadImage"
            class="form-control-lg"
          />
        </div>
        <div class="row form-check form-switch">
          <input
            v-model="useJson"
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
          />
          <label class="form-label" for="flexSwitchCheckDefault"
            >Use Json</label
          >
        </div>
        <div v-if="useJson" class="row">
          <label class="form-label" for="upload-json"
            >Upload Aseprite Json</label
          >
          <input
            type="file"
            accept="application/json"
            @change="uploadFile"
            class="form-control-lg"
            id="upload-json"
          />
        </div>
        <div v-if="!useJson" class="row">
          <label class="form-label" for="number-of-rows">Rows</label>
          <input
            v-model="frameCols"
            type="number"
            class="form-control-lg"
            id="number-of-rows"
          />
          <label class="form-label" for="number-of-cols">Columns</label>
          <input
            v-model="frameRows"
            type="number"
            class="form-control-lg"
            id="number-of-cols"
          />
        </div>
        <div v-for="item in previewGifs" :key="item.name">
          <img :src="item.image" :alt="item.name" />
        </div>
        <div class="btn"></div>
      </div>
      <!-- <div class="col mb-2 mb-md-0">
          <input
            type="submit"
            value="post"
            name="post"
            id="post"
            class="front btn btn-block btn-lg btn-primary"
          />
        </div> -->
    </form>
    <button
      @click="submitFuncApp"
      class="front btn btn-block btn-lg btn-primary"
    >
      Preview Gif
    </button>
    <!-- <button  class="front btn btn-block btn-lg btn-primary" @click="showPreview">Preview </button> -->
  </div>
  <div class="btn">
    <button @click="submit" class="front btn btn-block btn-lg btn-primary">
      Create Sprite
    </button>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed } from "vue";
import {
  // Sprite,
  SpriteFrameDataDto,
  FrameTagDto,
  // ImageType,
  // AnimationDirection
} from "@/types/API/sprites/Spritetypes";
import { useStore } from "vuex";

export default defineComponent({
  name: "CreateSprite",
  props: {
    imageType: {
      required: true
    }
  },
  setup() {
    const store = useStore();
    const name = ref("");
    const description = ref("");
    const previewImage = ref<ArrayBuffer | string | null>("");
    const imageJson = ref<SpriteFrameDataDto>({
      frames: [],
      meta: {
        monsterMakerFormat: true,
        image: "none",
        size: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        frameTags: []
      }
    });

    const frameSize = ref(64);
    const imageWidth = ref(64);
    const imageHeight = ref(64);
    const frameRows = ref(5);
    const frameCols = ref(6);
    const previewGifs = ref();
    const useJson = ref(true);
    const imageMetaData = ref<SpriteFrameDataDto>({
      frames: [],
      meta: {
        monsterMakerFormat: true,
        image: "none",
        size: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        },
        frameTags: []
      }
    });
    const apiUrl = process.env.VUE_APP_API_DEST;
    const funcUrl = process.env.VUE_APP_FUNC_DEST;
    const numberOfFrames = computed({
      get: () => frameRows.value * frameCols.value,
      set: value => {
        frameRows.value * frameCols.value;
      }
    });
    const computedRowsandCols = computed({
      get: () =>
        `grid-container g-row-${frameRows.value} g-col-${frameCols.value}`,
      set: value => {
        `grid-container g-row-${frameRows.value} g-col-${frameCols.value}`;
      }
    });
    function addAnimation(
      from: number,
      to: number,
      name: string,
      direction: string
    ) {
      imageMetaData.value.meta.frameTags.push({
        from: from,
        to: to,
        name: name,
        direction: direction
      } as FrameTagDto);
    }
    function addFrames(
      imageWidth: number,
      imageHeight: number,
      columns: number,
      rows: number
    ) {
      const frameX = imageWidth / columns;
      const frameY = imageHeight / rows;
      // const totalFrames = columns * rows
      for (let xCord = 0; xCord < rows; xCord++) {
        for (let yCord = 0; yCord < columns; yCord++) {
          imageMetaData.value.frames.push({
            filename: "filename",
            frame: {
              x: frameX * xCord,
              y: frameY * yCord,
              w: frameX,
              h: frameY
            }
          });
        }
      }
    }
    function submitFuncApp() {
      const headers = {
        "Content-Type": "application/json"
      };
      const body = JSON.stringify({
        image: previewImage.value,
        data: imageJson.value
      });
      console.log(body);
      fetch(funcUrl + "api/gif", {
        method: "POST",
        headers: headers,
        body: body
      })
        .then(response => {
          return response.json();
          // handle success
        })
        .then(response => {
          previewGifs.value = response;
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function submit() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        name: name.value,
        description: description.value,
        image_content: previewImage.value,
        image_type: 1,
        image_data: imageJson.value
      });
      fetch(apiUrl + "api/v1/monsters/sprites?language=english", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(function(response) {
          console.log(response);
          // handle success
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function uploadImage(e: any) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        if (e != null && e.target != null) {
          previewImage.value = e.target?.result;
          console.log(previewImage.value);
        }
      };
    }
    function uploadFile(e: any) {
      const jsonFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(jsonFile);
      reader.onload = e => {
        if (e != null && e.target != null && e.target.result != null) {
          imageJson.value = JSON.parse(
            e.target.result as string
          ) as SpriteFrameDataDto;
          //TODO: CONVERT FROM ASEPRITE JSON TO MOTN JSON?
          if (imageJson.value?.frames !== undefined) {
            imageJson.value.frames = Object.entries(imageJson.value.frames).map(
              ([k, v]) => v
            );
            console.log("It has not been defined");
          }
        }
        console.log(imageJson.value);
      };
    }
    return {
      submit,
      name,
      description,
      previewImage,
      imageJson,
      frameRows,
      frameCols,
      uploadImage,
      uploadFile,
      submitFuncApp,
      useJson,
      previewGifs,
      computedRowsandCols,
      numberOfFrames
    };
  }
});
</script>
<style></style>
