<template>
  <div class="container">
    <div class="row">
      <GetSprites></GetSprites>
    </div>
    <div class="row">
      <div class="col">
        <CreateSprite :imageType="0"></CreateSprite>
      </div>
      <div class="col">
        <CreateMonster></CreateMonster>
        <CreateStats></CreateStats>
      </div>
    </div>
  </div>
</template>
<script>
import GetSprites from "@/components/GetSprites.vue";
import CreateSprite from "@/components/CreateSprite.vue";
import CreateStats from "@/components/CreateStats.vue";
import CreateMonster from "@/components/CreateMonster.vue";
export default {
  name: "CreateMonsters",
  components: {
    CreateStats,
    CreateMonster,
    CreateSprite,
    GetSprites
  }
};
</script>
