// import { getURL } from "next/dist/next-server/lib/utils";
import {
  ActionContext,
  ActionTree,
  createStore,
  GetterTree,
  MutationTree
} from "vuex";

interface User {
  userName: string;
  index: number;
  avatarUrl: string;
  roles: Array<string>;
}

//Declare State definition
export type State = {
  jwt: string;
  user: User;
  loggedIn: boolean;
  loggingIn: boolean;
};
//Set State that will be used in store
const state: State = {
  jwt: "",
  user: {
    userName: "",
    index: 0,
    avatarUrl: "",
    roles: []
  },
  loggedIn: false,
  loggingIn: false
};
// Create shortcuts for mutating states
export enum MutationTypes {
  UpdateJwt = "UpdateJwt",
  UpdateUser = "UpdateUser",
  UpdateLoginStatus = "UpdateLoginStatus",
  UpdateLoggingInStatus = "UpdateLoggingInStatus"
}
export enum ActionTypes {
  GetToken = "getToken",
  RefreshToken = "refreshToken"
}
//Define mutation Type
export type Mutations<S = State> = {
  [MutationTypes.UpdateJwt](state: S, payload: string): void;
  [MutationTypes.UpdateUser](state: S, payload: User): void;
  [MutationTypes.UpdateLoginStatus](state: S, payload: boolean): void;
  [MutationTypes.UpdateLoggingInStatus](state: S, payload: boolean): void;
};

// Define actual Mutations
//MutationTree is a KeyValue pair interface, where the key is a string (in this case MutationTypes)
// And the value is the function associated
const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.UpdateJwt](state: State, payload: string): void {
    state.jwt = payload;
  },
  [MutationTypes.UpdateUser](state: State, payload: User) {
    state.user = payload;
  },
  [MutationTypes.UpdateLoginStatus](state: State, payload: boolean) {
    state.loggedIn = payload;
  },
  [MutationTypes.UpdateLoggingInStatus](state: State, payload: boolean) {
    state.loggingIn = payload;
  }
};

// Actions
//ActionContext is an interface that defines different properties and functions
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;
//We remove the concept of a "Commit" from this type

//Actions interface
export interface Actions {
  [ActionTypes.GetToken](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;
  [ActionTypes.RefreshToken]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.GetToken]({ commit }: AugmentedActionContext, payload: string) {
    commit(MutationTypes.UpdateLoggingInStatus, true);
    const apiUrl = process.env.VUE_APP_API_DEST;
    const response = fetch(
      apiUrl +
        "api/v1" +
        "/login/discord/" +
        payload +
        "&clientId=" +
        "819895297069154336",
      {
        method: "POST",
        credentials: "include"
      }
    )
      // handle success
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject();
        }
      })
      .then(res => {
        //console.log(res)
        commit(MutationTypes.UpdateJwt, res.apiToken);
        //TODO: This needs to be a  USER object
        commit(MutationTypes.UpdateUser, res.user);
        commit(MutationTypes.UpdateLoginStatus, true);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .finally(() => {
        commit(MutationTypes.UpdateLoggingInStatus, false);
      });
  },
  [ActionTypes.RefreshToken]({ commit }: AugmentedActionContext) {
    const apiUrl = process.env.VUE_APP_API_DEST;
    const response = fetch(apiUrl + "api/v1" + "/login/discord/refresh", {
      method: "GET",
      credentials: "include"
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject();
        }
      })
      .then(res => {
        //console.log(res)
        commit(MutationTypes.UpdateJwt, res.apiToken);
        commit(MutationTypes.UpdateUser, res.user);
        commit(MutationTypes.UpdateLoginStatus, true);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  }
};
export type Getters = {
  getJWT(state: State): string;
  getUser(state: State): User;
  //0 == not logged in, 1 == logging in, 2 == logged in
  getLoginStatus(state: State): number;
};
export const getters: GetterTree<State, State> & Getters = {
  getJWT: state => {
    return state.jwt;
  },
  getUser: state => {
    return state.user;
  },
  getLoginStatus: state => {
    return state.loggedIn ? 2 : state.loggingIn ? 1 : 0;
  }
};

export const store = createStore({
  state,
  getters,
  mutations,
  actions
});
