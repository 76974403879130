<template>
  <div>
    <form v-on:submit.prevent="submit" class="validate" target="_blank">
      <div class="form container">
        <div class="row">
          <div class="col">
            <label style="text-align-left" for="speed"
              >{{ speed }}
              <i class="icon-speedometer text-primary"></i> Speed</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="speed"
              name="speed"
              class="form-range"
              placeholder="5"
              id="speed"
            />
          </div>
          <div class="col">
            <label class="form-label" for="hp"
              >{{ hp }} <i class="icon-heart text-primary"></i> Hp</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="hp"
              name="hp"
              class="form-range"
              placeholder="5"
              id="hp"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label" for="strength"
              >{{ strength }}
              <i class="icon-energy text-primary"></i> Strength</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="strength"
              name="strength"
              class="form-range"
              placeholder="5"
              id="strength"
            />
          </div>
          <div class="col">
            <label class="form-label" for="defense"
              >{{ defense }}
              <i class="icon-shield text-primary"></i> Defense</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="defense"
              name="defense"
              class="form-range"
              placeholder="5"
              id="defense"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-label" for="power"
              >{{ power }}
              <i class="icon-magic-wand text-primary"></i> Power</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="power"
              name="power"
              class="form-range"
              placeholder="5"
              id="power"
            />
          </div>
          <div class="col">
            <label class="form-label" for="fortitude"
              >{{ fortitude }}
              <i class="icon-user text-primary"></i> Fortitude</label
            >
            <input
              type="range"
              min="0"
              max="20"
              v-model="fortitude"
              name="fortitude"
              class="form-range"
              placeholder="5"
              id="fortitude"
            />
          </div>
        </div>
        <div class="col mb-2 mb-md-0">
          <input
            type="submit"
            value="post"
            name="post"
            id="post"
            class="front btn btn-block btn-lg btn-primary"
          />
        </div>
      </div>
    </form>
    <!-- <button  class="front btn btn-block btn-lg btn-primary" @click="showPreview">Preview </button> -->
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "CreateStats",
  setup() {
    const store = useStore();
    const hp = ref(5);
    const strength = ref(5);
    const defense = ref(5);
    const power = ref(5);
    const fortitude = ref(5);
    const speed = ref(5);
    const apiUrl = process.env.VUE_APP_API_DEST;
    onMounted(() => getStats());
    function submit() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        hp: hp.value,
        strength: strength.value,
        defense: defense.value,
        power: power.value,
        fortitude: fortitude.value,
        speed: speed.value
      });
      fetch(apiUrl + "api/v1/monsters/stats", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(function(response) {
          // handle success
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function getStats() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json"
      };
      fetch(apiUrl + "api/v1/monsters/stats", {
        method: "GET",
        headers: headers
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    return {
      submit,
      hp,
      strength,
      defense,
      power,
      fortitude,
      speed,
      getStats
    };
  }
};
</script>
<style></style>
