<template>
  <div class="container">
    <div class="col-md text-left">
      <h1>FAQ</h1>
      <h3>When will the game be released?</h3>
      <p>
        The game is still in early development, it's difficult to say a
        guarenteed release date. Keep an eye on Twitter or the Newsletter if you
        want to know as soon as I announce it.
      </p>
      <h3>Will monsters die permanently in my game?</h3>
      <p>
        When a monster in your team dies, it is gone for the rest of the run. It
        will return in later runs.
      </p>
      <h3>What platforms will it release on?</h3>
      <p>
        Currently planned for PC, but I'm eyeballing the Switch for potential
        porting.
      </p>
      <h3>How does the multiplayer work?</h3>
      <p>
        When you complete a run, you can add a monster to your Online Roster.
        You use this roster to battle against other players.
      </p>
      <h3>Can I choose the pronouns for my character?</h3>
      <p>
        Absolutely. The player character is non-binary and you can decide what
        pronouns they prefer. The game also allows you to add your own
        neo-pronouns if you're feeling spicy.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "FrequentlyAskedQuestions"
};
</script>
