import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized
} from "vue-router";
import Home from "../views/Home.vue";
import SignUp from "../views/SignUp.vue";
import Editor from "../views/Editor.vue";
import BlogPostView from "../views/BlogPostView.vue";
import Login from "../views/Login.vue";
import MechsLogin from "../views/MechsLogin.vue";
import News from "../views/News.vue";
import CreateMonster from "../views/CreateMonster.vue";
import FAQ from "../views/FAQ.vue";
import AccessibilityView from "../views/Accessibility.vue";
import { ActionTypes, store } from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/editor",
    name: "Editor",
    component: Editor
  },
  {
    path: "/accessibility",
    name: "AccessibilityView",
    component: AccessibilityView
  },
  {
    path: "/news",
    name: "News",
    component: News
  },
  {
    path: "/create",
    name: "Create",
    component: CreateMonster
  },
  {
    path: "/blog/post/:postId",
    name: "BlogPostView",
    component: BlogPostView,
    props: true
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/auth/oauth2/callback/",
    name: "LoginHome",
    component: Home,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      if (to.query.code !== undefined) {
        let params = new URLSearchParams(document.location.search).toString();
        store.dispatch(ActionTypes.GetToken, "?" + params);
        console.log("Performing Request");
      }
      next();
    },
    props: true
  },
  {
    path: "/auth/oauth2/callback/mechs",
    name: "MechsLogin",
    component: MechsLogin,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      if (to.query.code !== undefined) {
        let params = new URLSearchParams(document.location.search).toString();
        const apiUrl = process.env.VUE_APP_API_DEST;
        const response = fetch(
          apiUrl +
            "api/v1" +
            "/login/discord/game?" +
            params +
            "&clientId=" +
            "819895297069154336",
          {
            method: "POST"
          }
        )
          .then(response => {
            if (response.ok) {
              return response;
            } else {
              return Promise.reject();
            }
          })
          .then(res => {
            console.log(res);
          })
          .catch(function(error) {
            // handle error
            console.log(error);
          });
        console.log("Logging in game");
      }
      next();
    },
    props: true
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
