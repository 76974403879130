<template>
  <Header></Header>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <Component :is="Component" />
    </transition>
  </router-view>
  <!-- Footer -->
  <Footer></Footer>
  <!--<input v-on:click="toggle" type="checkbox" class="form-check-input" id="flexSwitchCheckDefault">
      <label class="form-check-label" for="flexSwitchCheckDefault" >Toggle</label>
      -->
</template>

<script lang="ts">
import { onMounted } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ActionTypes } from "./store";
export default {
  components: {
    Header,
    Footer
  },
  props: {
    code: {
      type: String,
      required: false
    }
  },
  setup() {
    // const marked = require("marked")
    // const html = marked('# Marked in NODE JS \n\nRendered by **marked**.')
    const store = useStore();
    // const route = useRoute();
    onMounted(() => {
      store.dispatch(ActionTypes.RefreshToken);
    });
    return {};
  }
};
</script>

<style lang="scss">
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css";
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,300italic,400italic,700italic";
@import "assets/scss/landing-page";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #00aeff;
    }
  }
}
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
