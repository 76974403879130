
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Pagination",
  emits: ["update:index"],
  props: {
    index: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    function triggerEvent(indexed: number) {
      console.log("EVEN TRIGGERED");
      // emit('update:index', indexed)
      if (
        indexed > 0 &&
        indexed != currentIndex.value &&
        indexed <= props.max
      ) {
        currentIndex.value = indexed;
      }
    }
    const currentIndex = computed({
      get: () => props.index,
      set: value => {
        console.log("SET VALUE");
        console.log(value);
        emit("update:index", value);
      }
    });
    return {
      triggerEvent,
      currentIndex
    };
  }
});
