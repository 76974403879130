<template>
  <div>
    <!-- Masthead -->
    <header class="bg-dark">
      <br />
      <div class="overlay"></div>
      <a
        href="https://store.steampowered.com/app/1685930/Monsters_of_the_North/"
        target="_blank"
      >
        <img
          class="steam-btn"
          src="../assets/img/steam_wishlist_big.png"
          alt="Wishlist on Steam button"
        />
      </a>
      <SignUpForm></SignUpForm>
      <br />
      <br />
    </header>
    <!-- Icons Grid -->
    <section class="features-icons text-center">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>Get Code Dungeon for free by signing up!</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <iframe
            frameborder="0"
            src="https://itch.io/embed/606236?linkback=true"
          >
            <a href="https://stealcase.itch.io/codedungeon"
              >Code Dungeon by Stealcase, Waks</a
            >
          </iframe>
        </div>
      </div>
      <div class="container navbar-light bg-light">
        <div class="row">
          <div class="col-lg-4">
            <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div class="features-icons-icon d-flex">
                <i class="icon-book-open m-auto text-primary"></i>
              </div>
              <h3>Behind the scenes</h3>
              <p class="lead mb-0">
                Get exclusive details on development like sketches, ideas and
                rough drafts of designs.
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div class="features-icons-icon d-flex">
                <i class="icon-badge m-auto text-primary"></i>
              </div>
              <h3>First Notified</h3>
              <p class="lead mb-0">
                Be the first to get invited to the Open Beta!
              </p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="features-icons-item mx-auto mb-0 mb-lg-3">
              <div class="features-icons-icon d-flex">
                <i class="icon-ghost m-auto text-primary"></i>
              </div>
              <h3>Secret Monsters</h3>
              <p class="lead mb-0">
                Secret Codes to unlock monsters will be given through the
                mailing list!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SignUpForm from "./SignUpForm.vue";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
export default {
  components: { SignUpForm }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.title-logo {
  width: auto;
  height: 100%;
}

.shadow-text {
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.664);
}

::-webkit-input-placeholder {
  color: rgba(77, 154, 255, 0.74);
}

::-moz-placeholder {
  color: rgba(77, 154, 255, 0.74);
}

:-ms-input-placeholder {
  color: rgba(77, 154, 255, 0.74);
}

::-ms-input-placeholder {
  color: rgba(77, 154, 255, 0.74);
}

::placeholder {
  color: rgba(77, 154, 255, 0.74);
}

.front {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.664);
}

.navbar-img {
  width: 75%;
}

header.masthead .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

header.masthead h1 {
  font-size: 2rem;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.showcase .showcase-text {
  padding: 3rem;
}

.showcase .showcase-img {
  min-height: 30rem;
  background-size: cover;
}

@media (min-width: 768px) {
  .showcase .showcase-text {
    padding: 7rem;
  }
}

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  box-shadow: 0px 5px 5px 0px #adb5bd;
}

.call-to-action .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

footer.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

h2 {
  margin-bottom: 40px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  iframe {
    max-width: 100%;
    max-height: 100%;
  }
}
header.masthead {
  position: relative;
  background: url("https://cdn.monstersofthenorth.com/content/Landscape.jpg")
    center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
</style>
