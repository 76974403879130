<template>
  <div>
    <!-- <transition name="fade"> -->
    <transition name="fade">
      <div v-if="!firstTimeLoaded" class="sprite-loading">
        <div class="loader"></div>
        <!-- <p>Loading Monsters from the database</p> -->
      </div>
    </transition>
    <!-- </transition> -->
    <transition name="fade">
      <div v-if="loaded">
        <div class="sprite-grid">
          <div
            v-for="sprite in sprites.results"
            :key="sprite.id"
            class="sprite-container"
          >
            <div v-if="sprite.previews.length > 0 && sprite.previews[0].image">
              <!-- <div class="card-header">{{sprite.name.text}}
          </div> -->
              <img
                class="sprite"
                :src="sprite.previews[0].image"
                :alt="
                  sprite.name.text +
                    ' ' +
                    sprite.previews[0].name +
                    '.' +
                    ' ' +
                    sprite.description.text
                "
              />
              <!-- <button @click="deleteSprites(sprite.id)">Delete {{sprite.name.text}}</button> -->
              <!-- <div v-if="sprite.previews.length === 0"> <img :src="sprite.image_url" :alt="sprite.name.text + ' ' + sprite.description.text"/></div> -->
              <!-- <div v-for="item in sprite.previews" :key="item.name"> -->
              <!-- <div class="card-body">{{item.name}}</div> -->
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Pagination v-model:index="indexed" :max="maxCount"></Pagination>
  </div>
  <!-- <img :src="'https://'+sprite.image_url"/> -->
  <!-- <button  class="front btn btn-block btn-lg btn-primary" @click="showPreview">Preview </button> -->
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Pagination from "./Pagination.vue";
import {
  Sprite,
  PreviewDto,
  TranslationOutput,
  PagedResult
} from "@/types/API/sprites/Spritetypes";

export default defineComponent({
  name: "GetSprites",
  components: {
    Pagination
  },
  setup() {
    const store = useStore();
    const sprites = ref<PagedResult<Sprite>>();
    const apiUrl = process.env.VUE_APP_API_DEST;
    const loaded = ref(false);
    const firstTimeLoaded = ref(false);
    const currentIndex = ref(1);
    const indexed = computed({
      get: () => currentIndex.value,
      set: value => {
        currentIndex.value = value;
        getSprites(8, currentIndex.value);
      }
    });
    const maxCount = computed({
      get: () => {
        if (sprites.value === undefined) {
          return 1;
        } else {
          return sprites.value.pageCount;
        }
      },
      set() {}
    });
    onMounted(() => getSprites(8, currentIndex.value));
    function deleteSprites(spriteId: string) {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        Authorization: "Bearer " + store.getters.getJWT
      };
      fetch(apiUrl + "api/v1/monsters/sprites/" + spriteId, {
        method: "DELETE",
        headers: headers
      })
        .then(function(response) {
          return response.json();
          // handle success
        })
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function idleFrameFilter(
      preview: PreviewDto,
      index: number,
      array: PreviewDto[]
    ): boolean {
      return preview.name == "Idle";
    }
    function getIdleSprites(sprite: Sprite[]): Sprite[] {
      console.log("Getting idle sprites");
      console.log(sprite);
      for (let i = 0; i < sprite.length; i++) {
        sprite[i].previews = sprite[i].previews.filter(idleFrameFilter);
      }
      sprite = sprite.filter((spr: Sprite) => spr.previews.length > 0);
      console.log("Remainder:" + sprite.length);
      let remainder = 8 - sprite.length;
      let presetPreviews = [
        {
          name: "Questionmark",
          image:
            "https://monstermakerstorage.blob.core.windows.net/sprites/battle/Unknown_v2.png"
        }
      ] as PreviewDto[];
      if (remainder > 0) {
        for (let i = 0; i < remainder; i++) {
          sprite.push({
            id: `${sprite.length + 1}`,
            creator: {
              id: "System",
              userName: "System"
            },
            imageUrl: "none",
            imageData: {
              frames: [],
              meta: {
                monsterMakerFormat: false,
                image: "none",
                frameTags: [],
                size: {
                  x: 1,
                  y: 1,
                  w: 1,
                  h: 1
                }
              }
            },
            previews: presetPreviews,
            name: {
              text: "Unknown",
              id: "1",
              keyId: "string",
              language: "string"
            } as TranslationOutput,
            description: {
              text: "",
              id: "1",
              keyId: "string",
              language: "string"
            } as TranslationOutput
          });
        }
      }
      return sprite;
    }
    function getSprites(limit: number, page: number) {
      loaded.value = false;
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json"
        // Authorization: "Bearer " + store.getters.getJWT
      };
      fetch(
        apiUrl + `api/v1/sprites?language=english&limit=${limit}&page=${page}`,
        {
          method: "GET",
          headers: headers,
          credentials: "include"
        }
      )
        .then(function(response) {
          if (response.ok) {
            console.log(response);
            return response.json();
          } else {
            return Promise.reject(response);
          }
          // handle success
        })
        .then(function(res) {
          sprites.value = res as PagedResult<Sprite>;
          sprites.value.results = getIdleSprites(sprites.value.results);
          loaded.value = true;
          firstTimeLoaded.value = true;
          console.log(sprites.value);
        })
        .catch(function(error) {
          // handle error
          // sprites.value = getIdleSprites([
          //   {
          //     previews = []
          //   },
          //   {
          //     previews = []
          //   }
          // ])
          console.log(error);
        });
    }
    return {
      sprites,
      loaded,
      firstTimeLoaded,
      indexed,
      maxCount,
      deleteSprites
    };
  }
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
