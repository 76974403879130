<template>
  <div>
    <article class="blog" id="preview"></article>
    <img src="https://imgur.com/a88PNz3" alt="" />
    <form v-on:submit.prevent="submit" class="validate" target="_blank">
      <div class="form row">
        <div class="col-12 mb-1">
          <input
            type="text"
            v-model="blogTitle"
            name="title"
            class="front form-control form-control-lg"
            placeholder="Title"
            id="title"
          />
        </div>
        <div class="col-12 mb-1">
          <textarea
            v-model="blogContent"
            name="blog-content"
            required
            class="front required email form-control form-control-lg"
            placeholder="What do you want to say...?"
            id="content"
          />
        </div>
        <div class="col-12 mb-1">
          <input
            type="text"
            v-model="tags"
            name="tags"
            required
            class="front required email form-control form-control-lg"
            placeholder="comma separated tags"
            id="tags"
          />
        </div>
        <div class="col-12 mb-1">
          <label for="publish">Publish</label>
          <input
            type="checkbox"
            id="publish"
            name="publish"
            v-model="publish"
          />
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div class="col-12 mb-2 mb-md-0">
          <input
            type="submit"
            value="post"
            name="post"
            id="post"
            class="front btn btn-block btn-lg btn-primary"
          />
        </div>
      </div>
    </form>
    <button class="front btn btn-block btn-lg btn-primary" @click="showPreview">
      Preview
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "BlogPostCreate",
  setup() {
    const store = useStore();
    const blogTitle = ref("");
    const blogContent = ref("");
    const tags = ref("");
    const blogPostType = ref(0);
    const publish = ref(false);
    const preview = ref("");
    const apiUrl = process.env.VUE_APP_API_DEST;

    function showPreview() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        title: blogTitle.value,
        text: blogContent.value,
        tags: tags.value,
        postType: blogPostType.value,
        publish: publish.value
      });
      fetch(apiUrl + "api/v1/blog/preview", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(response => {
          // handle success
          //console.log(response);
          if (response.ok) {
            return response.json();
          }
        })
        .then(res => {
          preview.value = res.text;
          console.log("SETTING VALUES");
          var newNode = document.getElementById("preview");
          newNode.innerHTML = preview.value;
          //newNode.appendChild(document.createTextNode(preview.value))
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    function submit() {
      // Make a request for a user with a given ID
      //console.log(blogTitle.value + " " + blogContent.value + " " + store.getters.getJWT)
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      const body = JSON.stringify({
        title: blogTitle.value,
        text: blogContent.value,
        tags: tags.value,
        postType: blogPostType.value,
        publish: publish.value
      });
      fetch(apiUrl + "api/v1/blog", {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: body
      })
        .then(function(response) {
           console.log(response)
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    }
    return {
      submit,
      blogTitle,
      blogContent,
      tags,
      blogPostType,
      publish,
      preview,
      showPreview
    };
  }
};
</script>
<style></style>
