<template>
  <div class="container">
    <div v-if="done && !generatingKey && !fail" class="shadow-text text-white">
      <h3>
        Thanks for signing up! Check your inbox and verify that you're human and
        you'll get the key!
      </h3>
    </div>
    <div v-if="fail" class="shadow-text text-white">
      <h3>
        Oops, something went wrong! Have you already signed up? Check your inbox
        or junk folder for a confirmation email!
      </h3>
    </div>
    <div v-if="!done && generatingKey && !fail" class="shadow-text text-white">
      <h3>Generating Code Dungeon Key</h3>
      <div class="spinner-grow text-primary" role="status"></div>
    </div>
    <div v-if="!generatingKey && !done">
      <div class="row">
        <div class="col-3-9 mx-auto">
          <h1 class="mb-5 text-white text-center shadow-text">
            Sign up for the Newsletter!
          </h1>
          <h4 class="mb-5 text-white text-center shadow-text">
            Get Code Dungeon (my previous game) for free!
          </h4>

          <!-- Begin Mailchimp Signup Form -->
          <div class="col-lg-8 col-xl-7 mx-auto" id="mc_embed_signup_scroll">
            <form
              v-on:submit.prevent="addToNewsletter"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
            >
              <div class="form row">
                <div class="col-12 mb-1">
                  <input
                    type="text"
                    v-model="formFirstName"
                    name="FNAME"
                    class="front form-control form-control-lg"
                    placeholder="First name"
                    id="mce-FNAME"
                  />
                </div>
                <div class="col-12 mb-1">
                  <input
                    type="email"
                    v-model="formEmail"
                    name="EMAIL"
                    required
                    class="front required email form-control form-control-lg"
                    placeholder="Enter your email..."
                    id="mce-EMAIL"
                  />
                </div>
                <div id="mce-responses" class="clear">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display:none"
                  ></div>
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display:none"
                  ></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                  style="position: absolute; left: -5000px;"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    v-model="dummyValue"
                    name="b_17b003ac82c478bf743de4873_bc70e5646f"
                    tabindex="-1"
                  />
                </div>
                <div class="col-12 mb-2 mb-md-0">
                  <input
                    type="submit"
                    value="Sign up"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="front btn btn-block btn-lg btn-primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
export default {
  name: "SignUpForm",
  props: {
    msg: String
  },
  setup() {
    const formFirstName = ref("");
    const formEmail = ref("");
    const dummyValue = ref("");
    const generatingKey = ref(false);
    const done = ref(false);
    const fail = ref(false);

    function addToNewsletter() {
      const apiUrl = process.env.VUE_APP_API_DEST;
      //const apiUrl = "http://localhost:5000/";
      //Catch Bots
      if (dummyValue.value !== "") {
        return;
      }
      generatingKey.value = true;
      console.log(apiUrl + process.env.NODE_ENV);
      return axios
        .post(apiUrl + "api/v1" + "/users/subscribe", {
          firstName: formFirstName.value,
          email: formEmail.value,
          subscribe: true
        })
        .then(function(response) {
          if (response.status < 300) {
            generatingKey.value = false;
            done.value = true;
          }
        })
        .catch(function(error) {
          // handle error
          fail.value = true;
          console.log(error);
        });
    }

    function getMonsters() {
      const apiUrl = process.env.API_DEST;
      // Make a request for a user with a given ID
      axios
        .get(apiUrl + "api/v1" + "/monsters")
        .then(function(response) {
          // handle success
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
      // function subscribe () {
      //     //https://monstersofthenorth.us20.list-manage.com/subscribe/post?u=17b003ac82c478bf743de4873&amp;id=bc70e5646f
      // }
    }
    return {
      formFirstName,
      formEmail,
      dummyValue,
      done,
      fail,
      generatingKey,
      addToNewsletter,
      getMonsters
    };
  }
};
</script>

<style scoped></style>
