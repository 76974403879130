<template>
  <footer class="footer bg-dark">
    <div class="container">
      <div class="row">
        <div class="col">
          <SignUpForm></SignUpForm>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 h-100 text-center text-lg-center my-auto">
          <ul class="list-inline mb-0">
            <li class="list-inline-item mr-3">
              <a
                href="https://www.youtube.com/channel/UCn7Nx5cUebQtlGFHRsrHWqg?"
              >
                <i class="fab fa-youtube fa-2x fa-fw"></i>
                <div class="sr-only">Youtube</div>
              </a>
            </li>
            <li class="list-inline-item mr-3">
              <a href="https://twitter.com/stealcase">
                <i class="fab fa-twitter-square fa-2x fa-fw"></i>
                <div class="sr-only">Twitter</div>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="https://www.instagram.com/stealcase_gamedev/">
                <i class="fab fa-instagram fa-2x fa-fw"></i>
                <div class="sr-only">Instagram</div>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="https://store.steampowered.com/app/1685930/Monsters_of_the_North/"
                target="_blank"
              >
                <img
                  class="steam-btn"
                  src="../assets/img/steam_wishlist_small.png"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
          <p class="text-muted small mb-4 mb-rg-0">
            &copy; Monsters of the North 2021. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
    <p class="text-muted small mb-4 mb-rg-0 ">
      <img
        class="trademark"
        src="../assets/img/Steam_Icon.svg"
        alt="Steam Logo"
      />
      ©2021 Valve Corporation. Steam and the Steam logo are trademarks and/or
      registered trademarks of Valve Corporation in the U.S. and/or other
      countries.
    </p>
  </footer>
</template>
<script>
import SignUpForm from "./SignUpForm.vue";
export default {
  name: "Footer",
  components: { SignUpForm }
};
</script>

<style scoped>
.row {
  padding-bottom: 2rem;
}
.trademark {
  max-width: 3%;
  opacity: 0.3;
  height: auto;
}
</style>
