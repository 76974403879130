<template>
  <div class="container">
    <div class="row pagination">
      <button class="col button" @click="triggerEvent(1)">First</button>
      <!-- <button class="col button" @click="triggerEvent(currentIndex - 1)">Previous</button> -->
      <button
        class="col button"
        @click="triggerEvent(currentIndex - 1)"
        v-if="currentIndex > 1"
      >
        {{ currentIndex - 1 }}
      </button>
      <button class="col button btn btn-block btn-primary">
        {{ currentIndex }}
      </button>
      <button
        class="col button"
        @click="triggerEvent(currentIndex + 1)"
        v-if="max > currentIndex"
      >
        {{ currentIndex + 1 }}
      </button>
      <!-- <button class="col button" @click="triggerEvent(currentIndex + 1)">Next</button> -->
      <button class="col button" @click="triggerEvent(max)">Last</button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "Pagination",
  emits: ["update:index"],
  props: {
    index: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    function triggerEvent(indexed: number) {
      console.log("EVEN TRIGGERED");
      // emit('update:index', indexed)
      if (
        indexed > 0 &&
        indexed != currentIndex.value &&
        indexed <= props.max
      ) {
        currentIndex.value = indexed;
      }
    }
    const currentIndex = computed({
      get: () => props.index,
      set: value => {
        console.log("SET VALUE");
        console.log(value);
        emit("update:index", value);
      }
    });
    return {
      triggerEvent,
      currentIndex
    };
  }
});
</script>
