<template>
  <div>
    <form v-on:submit.prevent="submit" class="validate" target="_blank">
      <div class="form row">
        <div class="col-12 mb-1">
          <input
            type="text"
            v-model="title"
            name="title"
            class="front form-control form-control-lg"
            placeholder="Title"
            id="title"
          />
        </div>
        <div class="col-12 mb-1">
          <input
            type="text"
            v-model="description"
            name="description"
            required
            class="front required email form-control form-control-lg"
            placeholder="Sprite Description"
            id="content"
          />
        </div>
        <div class="col-12 mb-1">
          <input
            type="text"
            v-model="imageUrl"
            name="imageUrl"
            required
            class="front required email form-control form-control-lg"
            placeholder="Image Url"
            id="tags"
          />
        </div>
        <div class="col-12 mb-1">
          <input
            type="number"
            v-model="widthPx"
            name="width"
            required
            class="front required email form-control form-control-lg"
            placeholder="Width"
            id="width"
          />
        </div>
        <div class="col-12 mb-1">
          <input
            type="number"
            v-model="heightPx"
            name="height"
            required
            class="front required email form-control form-control-lg"
            placeholder="Height"
            id="height"
          />
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div class="col-12 mb-2 mb-md-0">
          <input
            type="submit"
            value="post"
            name="post"
            id="post"
            class="front btn btn-block btn-lg btn-primary"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "PostSprite",
  setup() {
    const store = useStore();
    const title = ref("");
    const description = ref("");
    const imageUrl = ref("");
    const widthPx = ref();
    const heightPx = ref();

    function submit() {
      const apiUrl = process.env.VUE_APP_API_DEST;
      // Make a request for a user with a given ID
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getJWT
      };
      axios
        .post(
          apiUrl + "api/v1/monsters/sprite",
          {
            title: title.value,
            text: blogContent.value,
            description: description.value,
            imageUrl: imageUrl.value,
            widthPx: widthPx.value,
            heightPx: heightPx.value
          },
          { headers: headers }
        )
        .then(function(response) {
          // handle success
          console.log(response);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    }
    return {
      title,
      description,
      imageUrl,
      widthPx,
      heightPx,
      submit
    };
  }
};
</script>
<style></style>
