<template>
  <div>
    <h2 name="accessibility" id="accessibility">Accessibility</h2>
    <ul>
      <li>
        The game is blind accessible, with Screenreader support and descriptions
        for monsters and visuals
      </li>
      <li>
        Super Cane navigation
      </li>
      <li>
        Contrast Options
      </li>
      <li>
        Scaleable Text
      </li>
      <li>
        Rebindable Controls
      </li>
      <li>
        Difficulty modifiers
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Accessibility"
};
</script>
